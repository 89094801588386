<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP SERVICE REQUEST VIEW

type    : view

uses    : client-one-line
          estimate-one-line
          header-view
          main-container
          main-card
          simple-dialog-template

route   : /serviceRequests/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-service-request-view">
    <header-view
      title="Service Request"
      short_title="SR"
      :edit_url="'/serviceRequests/edit/' + uuid"
      :allow_edit="serviceRequest.service_request_status == 'Submitted'"
      :btnOptions="headerButtons"
      :id="'SR-' + serviceRequest.number.toString().padStart(5, '0')"
      :status="serviceRequest.service_request_status"
      :job_uuid="serviceRequest.job_uuid"
      view_phase="Service Request"
      :previous_page="'/jobs/view/' + serviceRequest.job_uuid" />
    <main-container>
      <main-card>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Contact Name</b>
              </v-col>
              <v-col>
                {{ serviceRequest.requestor_name }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Contact Phone</b>
              </v-col>
              <v-col>
                {{ serviceRequest.requestor_phone }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Contact Email</b>
              </v-col>
              <v-col>
                {{ serviceRequest.requestor_email }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Address</b>
              </v-col>
              <v-col>
                <div>{{ serviceRequest.address }}</div>
                <div>
                  {{ serviceRequest.city }}, {{ serviceRequest.state }}
                  {{ serviceRequest.zip_code }}
                </div>
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Date Submitted</b>
              </v-col>
              <v-col>
                {{
                  displayDateFormat(
                    serviceRequest.date_submitted,
                    $auth.tenantProfile.date_format
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider :vertical="!$vuetify.breakpoint.xsOnly" />
          <v-col cols="12" sm="5">
            <v-row dense>
              <v-col class="text-left">
                <b>Special Instructions</b>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-left">
                {{ serviceRequest.special_instructions || 'None' }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </main-card>
      <client-one-line v-model="client" />
      <estimate-one-line v-model="estimate" />
    </main-container>
    <simple-dialog-template
      :open="approveConfirmDialog"
      dialogTitle="Approve Service Request"
      dialogText="Are you sure that you want to approve this service request?"
      dialogButtonOne="Yes"
      dialogButtonTwo="No"
      @buttonOne="approveConfirmed"
      @buttonTwo="cancelAction" />

    <simple-dialog-template
      :open="declineConfirmDialog"
      dialogTitle="Decline Service Request"
      dialogText="Are you sure that you want to decline this service request?"
      dialogButtonOne="Yes"
      dialogButtonTwo="No"
      @buttonOne="declineConfirmed"
      @buttonTwo="cancelAction" />
  </div>
</template>

<script>
  import email from '@/mixins/email';
  import ClientOneLine from '@/components/clients/client-one-line';
  import EstimateOneLine from '@/components/estimates/estimate-one-line';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';
  import HeaderView from '@/components/header-view';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppServiceRequestView',
    components: {
      'client-one-line': ClientOneLine,
      'estimate-one-line': EstimateOneLine,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      'simple-dialog-template': SimpleDialogTemplate,
    },
    mixins: [dateTimeHelpers, email, Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        serviceRequest: {
          uuid: '',
          requestor_name: '',
          requestor_phone: '',
          requestor_email: '',
          address: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
          special_instructions: '',
          service_request_status: 'Status',
          tenant_url: '',
          date_submitted: '',
          client_uuid: '',
          number: '',
        },
        estimate_uuid: '',
        estimate: {},
        client: {},
        approveConfirmDialog: false,
        declineConfirmDialog: false,
        savedProperlyDialog: false,
      };
    },
    computed: {
      headerButtons: function () {
        if (this.serviceRequest.service_request_status == 'Submitted') {
          return [
            {
              name: 'Approve',
              btnColor: 'button-primary',
              icon: 'mdi-check',
              action: () => {
                this.approveConfirmDialog = true;
              },
            },
            {
              name: 'Decline',
              btnColor: 'button-secondary',
              color: '',
              icon: 'mdi-close',
              action: () => {
                this.declineConfirmDialog = true;
              },
            },
          ];
        } else {
          return [];
        }
      },
    },
    async created() {
      if (!this.uuid) {
        console.log(
          'uuid passed in to AppServiceRequestView was undefined: ',
          this.uuid
        );
        return;
      }

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get service request being viewed
      var service_request = await Jobs.getServiceRequest(
        this.uuid,
        accessToken
      );

      if (service_request) {
        this.serviceRequest = service_request;
        this.client = await Clients.getClient(
          service_request.client_uuid,
          accessToken
        );
      }

      var estimate = await Jobs.getServiceRequestEstimate(
        this.uuid,
        accessToken
      );

      if (estimate) {
        this.estimate = estimate;
        this.estimate_uuid = estimate.uuid;
      }
    },
    methods: {
      statusChanged: function (event) {
        if (event == 'Approve') {
          this.approveConfirmDialog = true;

          // we don't actually have a cancel confirm dialog yet
          // } else if ( event == "Cancel" ) {
          //   this.cancelConfirmDialog = true
        } else if (event == 'Decline') {
          this.declineConfirmDialog = true;
        }
      },
      async approveConfirmed() {
        this.approveConfirmDialog = false;
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update job phase
        await Jobs.updateJobPhase(
          this.serviceRequest.job_uuid,
          'Estimate'
          // unsecured route
        );

        // approve service request
        var res = await Jobs.approveServiceRequest(this.uuid, accessToken);

        if (res) {
          this.savedProperlyDialog = true;

          // disabled until event creation is vetted and assigned
          //  const url =
          //   window.location.protocol +
          //   '//' +
          //   window.location.hostname +
          //   '/serviceRequests/view/' +
          //   this.uuid;
          // this.createEvent({
          //   link: url,
          //   users: [this.serviceRequest.client_uuid],
          //   notification_name: 'SERVICE-REQUEST-APPROVED',
          //   clientUuid: this.serviceRequest.client_uuid,
          // });
          this.$router.go(0);
        } else {
          console.log('failed to approve service request');
        }
      },
      async declineConfirmed() {
        this.declineConfirmDialog = false;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // decline service request
        var res = await Jobs.declineServiceRequest(this.uuid, accessToken);

        if (res) {
          this.savedProperlyDialog = true;

          // disabled until event creation is vetted and assigned
          // const url =
          //   window.location.protocol +
          //   '//' +
          //   window.location.hostname +
          //   '/serviceRequests/view/' +
          //   this.uuid;
          // this.createEvent({
          //   link: url,
          //   users: [this.serviceRequest.client_uuid],
          //   notification_name: 'SERVICE-REQUEST-DECLINED',
          //   clientUuid: this.serviceRequest.client_uuid,
          // });
          this.$router.go(0);
        } else {
          console.log('failed to decline service request');
        }
      },
      savedProperlyDialogClose: function () {
        this.savedProperlyDialogClose = false;
        this.$router.go(0);
      },
      cancelAction: function () {
        this.approveConfirmDialog = false;
        this.declineConfirmDialog = false;
        this.savedProperlyDialog = false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
