<template>
  <main-card class="client-one-line-component">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">CLIENT</th>
            <th class="text-left">ADDRESS</th>
            <th class="text-left">REPRESENTATIVE</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="viewClient()">
            <td>{{ clientName }}</td>
            <td>{{ clientAddress }}</td>
            <td>{{ clientRep }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </main-card>
</template>

<script>
  import MainCard from '@/components/main-card';
  export default {
    name: 'ClientOneLine',
    components: {
      'main-card': MainCard,
    },
    props: {
      value: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {};
    },
    computed: {
      clientName: function () {
        if (this.value.hasOwnProperty('client_name')) {
          return this.value.client_name;
        } else {
          return '';
        }
      },
      clientAddress: function () {
        var address = '';
        if (this.value.hasOwnProperty('address')) {
          address = this.value.address;
        }
        if (this.value.hasOwnProperty('city')) {
          address = address + ' ' + this.value.city;
        }
        if (this.value.hasOwnProperty('state')) {
          address = address + ' ' + this.value.state;
        }
        return address;
      },
      clientRep: function () {
        if (this.value.hasOwnProperty('contact_name')) {
          return this.value.contact_name;
        } else {
          return '';
        }
      },
    },
    mounted() {},
    methods: {
      viewClient() {
        if (this.value.hasOwnProperty('uuid') && this.value.uuid) {
          this.$router.push({
            name: 'ClientView',
            params: {
              uuid: this.value.uuid,
            },
          });
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
