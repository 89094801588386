<!-- ----------------------------------------------------------------------- -->
<!--
name    : ESTIMATE ONE LINE

type    : component

used by : AppServiceRequestView

uses    : main-card

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <main-card>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ESTIMATE</th>
            <th class="text-left">DATE CREATED</th>
            <th class="text-left">NO REVISIONS</th>
            <th class="text-left">LAST SUBMIT DATE</th>
            <th class="text-left">DATE APPROVED</th>
            <th class="text-left">STATUS</th>
          </tr>
        </thead>
        <tbody v-if="value">
          <tr @click="viewEstimate()">
            <td>{{ estimateNumber }}</td>
            <td>{{ displayDateFormat(value.date_created, dateFormat) }}</td>
            <td>{{ revisionCount }}</td>
            <td>{{ displayDateFormat(value.submitted_date, dateFormat) }}</td>
            <td>{{ displayDateFormat(value.approved_date, dateFormat) }}</td>
            <td>{{ status }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </main-card>
</template>

<script>
  import MainCard from '@/components/main-card';
  import dateTimeHelperFunctions from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';

  export default {
    name: 'EstimateOneLine',
    components: {
      'main-card': MainCard,
    },
    mixins: [dateTimeHelperFunctions, Localization],
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    async created() {
      //do something after creating vue instance
      // console.log( "estimate one line: ", this.value.submitted_date )
    },
    computed: {
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      estimateNumber() {
        if (this.value.hasOwnProperty('number')) {
          return 'EST-' + this.value.number.toString().padStart(5, '0');
        } else {
          return 'Estimate Not Created';
        }
      },
      revisionCount() {
        if (
          this.value.hasOwnProperty('revision_comments') &&
          this.value.revision_comments
        ) {
          return this.value.revision_comments.length;
        } else {
          if (this.value.hasOwnProperty('number')) {
            return 0;
          } else {
            return '';
          }
        }
      },
      dateCreated() {
        if (
          this.value.hasOwnProperty('date_created') &&
          this.value.date_created
        ) {
          var cDate = new Date(this.value.date_created);
          return cDate.toLocaleDateString('en-US');
        } else {
          return '';
        }
      },
      dateSubmitted() {
        if (
          this.value.hasOwnProperty('submitted_date') &&
          this.value.submitted_date
        ) {
          var sDate = new Date(this.value.submitted_date);
          return sDate.toLocaleDateString('en-US');
        } else {
          return '';
        }
      },
      dateApproved() {
        if (
          this.value.hasOwnProperty('approved_date') &&
          this.value.approved_date
        ) {
          var aDate = new Date(this.value.approved_date);
          return aDate.toLocaleDateString('en-US');
        } else {
          return '';
        }
      },
      status() {
        if (this.value.hasOwnProperty('status')) {
          return this.value.status;
        } else {
          return '';
        }
      },
    },
    methods: {
      async viewEstimate() {
        if (this.value.hasOwnProperty('uuid') && this.value.uuid) {
          this.$router.push('/estimates/view/' + this.value.uuid);
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
